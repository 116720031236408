
.my-pending-picks {
  margin-top: 16px;
  .my-pending-picks-detail-modal {
    .notfound {
      font-weight: bold;
      text-align: center;
      margin-top: 10%;
      margin-bottom: 10%;
    }
  }
  .modal-footer {
    border-top: 0.5px solid #e0e0e0;
    padding: 15px 0 0;
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    button {
      padding: 10px 15px;
      background: #6c757d;
      color: #fff;
      border-radius: 2px;
      font-size: 16px;
    }
  }
  .modal-content {
    .notfound {
      font-weight: bold;
      text-align: center;
      margin-top: 10%;
      margin-bottom: 10%;
    }
  }
  .modal-content {
    .badge {
      min-width: 16px;
      width: 16px;
      height: 16px;
      border-radius: 500px;
      color: #ffffff;
      font-style: normal;
      font-weight: 700;
      font-size: 6px;
      line-height: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: red;
    }

    table {
      .resultrow {
        font-weight: 500 !important;
        span.success {
          color: #20a013;
          font-weight: 500 !important;
        }
        span.lose {
          color: #e40808;
          font-weight: 500 !important;
        }
      }
    }
  }
  .notfound {
    font-weight: bold;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .content {
    margin-top: 16px;
    background: #ffffff;
    border-radius: 8px;
    padding: 12px;
    .notfound {
      font-weight: bold;
      text-align: center;
      margin-top: 10px;
    }
    table {
      background: #ffffff;
      border-spacing: 0;
      border-collapse: separate;
      width: 100%;
      @media screen and (max-width: 1024px) {
        margin: 0 0 16px !important;
      }
      tr th,
      tr td {
        border-right: 0.5px solid #e0e0e0;
        border-bottom: 0.5px solid #e0e0e0;
        &:first-child {
          border-left: 0.5px solid #e0e0e0;
        }
      }
      tr th {
        background: #fafafa;
        border-top: 0.5px solid #e0e0e0;
      }

      /* top-left border-radius */
      tr:first-child th:first-child {
        border-top-left-radius: 4px;
      }

      /* top-right border-radius */
      tr:first-child th:last-child {
        border-top-right-radius: 4px;
      }

      /* bottom-left border-radius */
      tr:last-child td:first-child {
        border-bottom-left-radius: 4px;
      }

      /* bottom-right border-radius */
      tr:last-child td:last-child {
        border-bottom-right-radius: 4px;
      }
      thead {
        tr {
          th {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            /* identical to box height */
            text-align: center;
            text-transform: uppercase;
            color: #000000;
            padding: 12px 16px;
            white-space: nowrap;

            @media screen and (max-width: 959px) {
              font-size: 14px !important;
              font-weight: 600 !important;
            }
            &.date {
              width: 15%;
            }
            &.risk,
            &.result,
            &.towin {
              width: 12%;
            }
            &.detail {
              width: 49%;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px 8px;
            font-size: 15px;
            line-height: 18px;
            white-space: nowrap;
            color: #000000;

            @media screen and (max-width: 959px) {
              font-size: 14px !important;
              font-weight: 600 !important;
            }
            &.date {
              font-style: normal;
              /* identical to box height */
              text-align: center;
              text-transform: uppercase;
              color: #000000;
            }
            &.risk,
            &.towin {
              font-style: normal;
              /* identical to box height */
              text-align: center;
              text-transform: uppercase;
              color: #000000;
            }
            &.detail {
              div {
                display: flex;
                align-items: flex-end;
                gap: 12px;
                .text {
                  font-style: normal;
                  cursor: pointer;
                  text-decoration-line: underline;
                  color: #000000;
                  @media screen and (max-width: 959px) {
                    font-size: 14px !important;
                  }
                }

                .text-ellipse-mobile {
                  width: 140px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  text-decoration: none;

                  @media screen and (max-width: 767px) {
                    font-size: 16px !important;
                  }

                  &:hover {
                    width: auto;
                    white-space: normal;
                  }
                }

                .badge {
                  min-width: 16px;
                  width: 16px;
                  height: 16px;
                  border-radius: 500px;
                  color: #ffffff;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 6px;
                  line-height: 7px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: red;
                }
              }
            }
            &.result {
              div {
                display: flex;
                justify-content: space-around;
                align-items: center;
                img {
                  cursor: pointer;
                  @media screen and (max-width: 767px) {
                    width: 20px;
                  }
                }
                span {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .content {
      padding: 0;
      table tbody tr td.result div {
        // flex-direction: column;
        gap: 8px;
      }
    }
  }
}
